*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App {
  text-align: center;
}
.banner {
  position: absolute;
  background: transparent;
  
  border: 3px solid orange;
  color: whitesmoke;
  
  top: 28%;
  padding: 2rem 1rem;
  text-align: center;
  text-transform: capitalize;
  word-break: break-word;
  
}

.div > * {
  z-index: 100;
}
.div {
  overflow: hidden;
  background-image:  url("../src/img/pool_ruza.jpg");
  background-size: cover;
}

.div::after {
  content: "";  
  position: absolute;
  width: 75%; 
  height: 100%;
  top: 0;
  left: 50%; 
  transform: skew(20deg) 
             translateX(-50%); 
  background-image: linear-gradient(120deg,#eaee44,#33d0ff);
  opacity: 0.6;
}


.btn-primary:hover {
  background: transparent;
  color: whitesmoke;
  border: 2px solid orange;
}
.myruler {
  border-top: 3px solid rgb(141, 130, 130);
  width: 70%;
        margin-left: auto;
        margin-right: auto;
}

.sliderimg{
  width: 100%;
  height: 100vh;
  object-fit: cover
}